import React, { Fragment, useEffect, useState } from 'react';
import Module from '../../utils/Module/Module';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import UserRow from '../UserRow';
import Search from './Search';
import Show from './Show';
import ReactLoading from 'react-loading';

const ModuleList: React.FC<any> = () => {
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently is an empty array.
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect is declared with a dependency array. Here the promise is returned as void.
  useEffect(() => {
    const getAdminUsersModule = async () => {
      // ToDo: organisation based module list.
      // const organizationAdminObj = new Module();
      // const result = await organizationAdminObj.getAllModule();
      setData([]);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    void getAdminUsersModule();
  }, []);

  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <h1 className="text-black text-xl leading-tight font-medium mb-2">Indigo Modules</h1>
          <br />
          <hr />
          <br />
          <Search />
          <Show />
          <br />
          <br />
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={500}
            />
          ) : (
            <div className="w-full">
              {data.length > 0 ? (
                <table className="mx-auto max-w-4xl w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-left">
                      <th className="font-semibold text-sm uppercase px-6 py-4"> Module Name </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Access level{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Action{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {/* data is mapped into db and the values are stored of type IModuleList. Here the index is of type number which generally determines the key value */}
                    {data.map((dataSet: IModuleList, index: number) => (
                      <UserRow
                        key={index}
                        module={dataSet.moduleName!}
                        level={dataSet.orgAccessList}
                        buttonStatus={dataSet.buttonStatus!}
                        name={dataSet.name!}
                        email={dataSet.email!}
                        role={dataSet.role!}
                        status={dataSet.status!}
                        progress={dataSet.progress!}
                        icon={dataSet.icon}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <h1 className="text-bold">No data found ☹️ !!</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ModuleList;
