import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminPanel from './pages/AdminPanel';
import BtAdminPanel from './pages/BtAdminPanel';
import ConfirmRegistration from './pages/ConfirmRegistration';
import { ProtectedRoutes } from './components/ProtectedRoute';

function App(): any {
  return (
    <Routes>
      <Route path="/" index={true} element={<Dashboard />}></Route>
      <Route path="/register" element={<Register />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/admin" element={<AdminPanel />}></Route>
        <Route path="/btadmin" element={<BtAdminPanel />}></Route>
      </Route>
      <Route path="/otp" element={<ConfirmRegistration />}></Route>
    </Routes>
  );
}

export default App;
