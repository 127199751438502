import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UserEmailInfo from '../components/register/userEmailInfo';
import UserDetails from '../components/register/userDetails';
import PartnerCompanyDetails from '../components/register/partnerComapnyDetails';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import BtLogo from '../library/Logo/BtLogo';
import User from '../utils/User/User';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';

const Register: React.FC<any> = () => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessahe: string | null;
  }
  const [inputEmail, setInputEmail] = useState<string>('');
  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [isEmailEnable, setEmailEnable] = useState<boolean>(false);
  const [error, setError] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  const [isSaveUserDetails, setSaveUserDetails] = useState<boolean>(false);
  const [isSaveUserPcd, setSaveUserPcd] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [fname, setFname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [jobTitle, setjobTitle] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword]: any = useState({});
  const [partnerCompanyName, setpartnerCompanyName] = useState<string>('');
  const [companyHeadQuaterAddress, setcompanyHeadQuaterAddress] = useState<string>('');
  const [description, setdescription] = useState<string>('');
  const [doj, setDoj] = useState<string>('');
  const [userCreatedStatus, setuserCreatedStatus]: any = useState({
    isSusuccessfull: false,
    errMessage: ''
  });

  const [message, setmessage] = useState<string>('');
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently is false.
  const { btadmin } = queryString.parse(useLocation().search);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
    navigate(btadmin ? '/btadmin' : '/login');
  };

  useEffect(() => {
    document.addEventListener('click', handleChange, isEmailEnable);
    return () => {
      document.removeEventListener('click', handleChange, isEmailEnable);
    };
  }, [isEmailEnable]);

  const clickRef: any = useRef(null);

  function isValidEmail(email: string): boolean {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

    return regex.test(email);
  }

  const handleChange = (event: any): any => {
    if (clickRef.current && !clickRef.current.contains(event.target) && isEmailEnable) {
      if (!isValidEmail(clickRef.current.value)) {
        setisEmailValid(false);
        setError({
          errorMessage: `Username has to be an email address.
        Please try again.`,
          errorConfirmMessahe: ''
        });
      } else {
        setisEmailValid(true);
        setError({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setError({ errorMessage: null, errorConfirmMessahe: null });
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'userEmail') {
        setInputEmail(event.target.value);
        isValidEmail(event.target.value) ? setisEmailValid(true) : setisEmailValid(false);

        setError({
          errorMessage: null,
          errorConfirmMessahe: error.errorConfirmMessahe
        });
      } else {
        setInputEmail(inputEmail);
        setError({
          errorMessage: error.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };

  const createRequestBody = () => {
    return {
      email: inputEmail,
      title,
      fname,
      lname,
      jobTitle,
      phone,
      password: password.password,
      partnerCompanyName,
      companyHaedquaterAddress: companyHeadQuaterAddress,
      description
    };
  };
  const createUser = async () => {
    const userObj = new User(createRequestBody());
    const userstatus = await userObj.createUser();
    switch (userstatus.statusCode) {
      case 201:
        setmessage(userstatus.body);
        setShow(true);
        setuserCreatedStatus({
          isSusuccessfull: true
        });
        break;
      default:
        setmessage(userstatus.body);
        setShow(true);
        setuserCreatedStatus({
          isSusuccessfull: false
        });
    }
  };

  return (
    <div className="visible block box-border text-[16px] h-full w-full flex-1 py-4">
      {btadmin ? (
        <Link className="lg:max-h-12 " to=" " title="Home" rel="home">
          <BtLogo />
        </Link>
      ) : (
        <Header />
      )}

      <div
        className={
          userCreatedStatus.isSusuccessfull
            ? 'max-w-[1220px] pl-[30px] pr-[30px] bg-white mr-auto ml-auto min-h-[810px]'
            : 'max-w-[1220px] pl-[30px] pr-[30px] bg-white mr-auto ml-auto'
        }
      >
        {userCreatedStatus.isSusuccessfull === false && (
          <div>
            {' '}
            {btadmin ? (
              <div className="block">
                <Link className="cursor-pointer font-[400] text-black no-underline" to={'/btadmin'}>
                  <div className="h-2.5"></div>
                  <i
                    className="relative inline-block -top-[2px] w-[8px] h-[8px] border-t-[1px] border-r-[1px] mr-[0.5em] ml-0.5 border-r-[bt-purple] border-t-[bt-purple]"
                    style={{
                      transform: 'rotate(228deg)',
                      content: ''
                    }}
                  ></i>
                  Back
                </Link>
              </div>
            ) : (
              <div className="block">
                <Link className="cursor-pointer font-[400] text-black no-underline" to={'/'}>
                  <div className="h-2.5"></div>
                  <i
                    className="relative inline-block -top-[2px] w-[8px] h-[8px] border-t-[1px] border-r-[1px] mr-[0.5em] ml-0.5 border-r-[bt-purple] border-t-[bt-purple]"
                    style={{
                      transform: 'rotate(228deg)',
                      content: ''
                    }}
                  ></i>
                  Back to Dashboard
                </Link>
              </div>
            )}
            <UserDetails
              isSaveUserDetails={isSaveUserDetails}
              title={title}
              setTitle={setTitle}
              fname={fname}
              setFname={setFname}
              lname={lname}
              setLname={setLname}
              jobTitle={jobTitle}
              setjobTitle={setjobTitle}
              setPhone={setPhone}
              phone={phone}
              setSaveUserDetails={setSaveUserDetails}
            />
            <PartnerCompanyDetails
              isSaveUserDetails={isSaveUserDetails}
              isSaveUserPcd={isSaveUserPcd}
              setSaveUserPcd={setSaveUserPcd}
              setpartnerCompanyName={setpartnerCompanyName}
              partnerCompanyName={partnerCompanyName}
              companyHeadQuaterAddress={companyHeadQuaterAddress}
              setcompanyHeadQuaterAddress={setcompanyHeadQuaterAddress}
              doj={doj}
              setDoj={setDoj}
              setdescription={setdescription}
              description={description}
              setSaveUserDetails={setSaveUserDetails}
            />
            <UserEmailInfo
              isSaveUserDetails={isSaveUserDetails}
              isSaveUserPcd={isSaveUserPcd}
              inputEmail={inputEmail}
              handleOnChange={handleOnChange}
              clickRef={clickRef}
              error={error}
              isEmailValid={isEmailValid}
              password={password}
              setPassword={setPassword}
              setSaveUserPcd={setSaveUserPcd}
              createUser={createUser}
              setEmailEnable={setEmailEnable}
            />
          </div>
        )}

        {/* Start Success tab */}
        {/* {userCreatedStatus.isSusuccessfull && <Register />} */}
        {/* End Success tab */}
        <CustomPopup onClose={popupCloseHandler} show={show}>
          <div className="max-h-30% ">
            <p className="mt-0 mr-0 mb-0.5 ml-0 text-[17px]">{message}</p>
          </div>
        </CustomPopup>
      </div>

      {/* <Footer /> */}
      <div className="w-full" style={isSaveUserDetails ? { padding: '64px' } : { padding: '0px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
