import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LeftPanel from '../components/AdminPanel/LeftPanel';
import ProgressBar from '../components/AdminPanel/ProgressBar';
import UserList from '../components/AdminPanel/UserList';
import ImageTag from '../library/ImageTag/ImageTag';
import BtLogo from '../library/Logo/BtLogo';
import ModuleList from '../components/AdminPanel/ModuleList';
import Avatar from '../assets/img/avatar.png';
import CardAdmin from '../components/AdminPanel/CardAdmin';

const AdminPanel: React.FC<any> = () => {
  // Storing the user's name and displaying it as a welcome page
  const userEmail: any = sessionStorage.getItem('userEmail');
  let name = userEmail.match(/^([^@]*)@/)[1];
  name = name.trim();
  name = name.replace('.', ' ');

  // Logging out user from the page
  const logOut = () => {
    sessionStorage.clear();
    window.location.href = '/login';
  };

  return (
    <Fragment>
      <div className="overflow-hidden relative block">
        <div className="w-[300px] h-[6rem] inline-block text-left left-0 top-0 bg-white z-[52] fixed">
          <div className="flex w-full h-full justify-start items-center text-white px-[50px]">
            <BtLogo />
          </div>
        </div>
        <LeftPanel />
        <div className="fixed top-0 w-full h-[6rem] p-0  z-[51] pl-[300px] bg-[#f8f8f8]">
          <div className="flex justify-between items-center h-full py-[22px] px-[30px] float-left" />
          <div className="flex justify-between items-center h-full py-[22px] px-[30px] float-right">
            <div className="flex items-center">
              <div className="bg-white py-2.5 px-3 relative rounded-[5px]">
                <div className="p-0 block outline-0">
                  <Link to={''} className="flex justify-between items-center text-black">
                    <div className="pr-5 text-right">
                      <span className="font-[500] leading-[24px] text-black">
                        Mr.{' '}
                        {(name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
                          .split(' ')
                          .slice(0, -1)
                          .join(' ')}
                      </span>
                      <small className="block font-[400] text-[12px] leading-[1]">Admin</small>
                      <div>
                        <Link className="border-0 py-2 pl-[1.5rem] pr-[-0.5rem] mx-[5px]" to={''}>
                          <span className="text-[#2c323f]">Profile</span>
                        </Link>
                        <Link className="border-0 py-2 pl-[1.5rem] pr-[-0.5rem] mx-[5px]" to={''}>
                          <span className="text-[#2c323f]" onClick={logOut}>
                            Logout
                          </span>
                        </Link>
                      </div>
                    </div>
                    <ImageTag width="37px" height="37px" src={Avatar} alt="people"></ImageTag>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-[300px] pt-[100px] z-0">
          <div className="pt-[30px] pr-[20px] pl-[20px]">
            <div className="p-0 pb-[30px] rounded-[5px] mx-0 flex flex-wrap">
              <div className="p-0 w-full">
                <h4 className="text-black text-[22px] mb-0.5 leading-[1.2]">
                  Hello,
                  <span>
                    {' '}
                    Mr.{' '}
                    {(name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
                      .split(' ')
                      .slice(0, -1)
                      .join(' ')}
                  </span>{' '}
                  - Current status of organisation onboarding
                </h4>
                <p className="mb-0 text-[12px] mt-0 block">
                  Indigo Partner Portal | Admin Dashboard
                </p>
              </div>
            </div>
          </div>
          {/* Progress bar */}
          <ProgressBar />
          {/* Progress bar end */}
        </div>
        {/* Card Component */}
        {/* Card Component */}
        <div className="lg:col-start-2 col-span-6  mx-1 lg:mx-0  md:flex  lg:flex flex-row justify-between mt-8 lg:mt-18">
          <CardAdmin />
        </div>
        <div className="flex w-full ml-[300px] pt-[100px]">
          <div className=" md:flex lg:flex flex-row justify-start mt-8 lg:mt-18 w-[54%] pl-2.5 pr-5">
            <UserList />
          </div>
          <div className="md:flex lg:flex flex-row justify-start mt-8 lg:mt-18 w-[26%] pr-2.5">
            <ModuleList />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminPanel;
