import React, { useState, Fragment, useEffect } from 'react';
import Button from '../../library/Button/button';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import edit from '../../assets/img/edit.png';
import { useNavigate } from 'react-router-dom';
import UpdateOrganization from '../../utils/UpdateOrganizationAdmin/UpdateOrgAdmin';
import { Dropdown } from '../Dropdown';

// Re-usable component for updating Organization users for a specific Organization Admin
export const EditAdminUserForm = (dataSet: any) => {
  // Interface for declaring data types
  interface formDetails {
    email?: string | null;
    title?: string | null;
    fname?: string | null;
    lname?: string | null;
    jobTitle?: string | null;
    phone?: string | null;
    partnerCompanyName?: string | null;
    companyHaedquaterAddress?: string | null;
    description?: string | null;
  }
  const optionTitle = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Professor', value: 'Professor' },
    { label: 'Sir', value: 'Sir' }
  ];

  const optionJobTitle = [
    { label: 'Developer', value: 'Developer' },
    { label: 'Admin', value: 'Admin' }
  ];
  const [showForm, setShowForm] = useState<boolean>(false);
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently the db value.
  const [email, setEmail] = useState(dataSet.email);
  const [title, setTitle] = useState(dataSet.title);
  const [fname, setFname] = useState(dataSet.fname);
  const [lname, setLname] = useState(dataSet.lname);
  const [jobTitle, setJobTitle] = useState(dataSet.jobTitle);
  const [phone, setPhone] = useState(dataSet.phone);
  const [partnerCompanyName, setPartnerCompanyName] = useState(dataSet.partnerCompanyName);
  const [companyHaedquaterAddress, setCompanyHeadQuarterAddress] = useState(
    dataSet.companyHaedquaterAddress
  );
  const [description, setDescription] = useState(dataSet.description);
  // Declaring state for handling errors.
  const [errors, setErrors] = useState<formDetails>({});
  const [showLoader, setShowLoader] = useState(false);

  // Default typescript library to navigate to a certain path after user is created successfully
  const navigate = useNavigate();

  // Form validation code added to useState
  useEffect(() => {
    validateForm();
  }, [title, fname, lname, companyHaedquaterAddress, description]);

  // Form validation code added here
  const validateForm = () => {
    const errors: formDetails = {};

    if (!fname.trim()) {
      errors.fname = 'First Name is required';
    }
    if (!lname.trim()) {
      errors.lname = 'Last Name is required';
    }
    if (!companyHaedquaterAddress.trim()) {
      errors.companyHaedquaterAddress = 'Address is required';
    }
    if (!description.trim()) {
      errors.description = 'Description is required';
    }
    if (!phone.trim()) {
      errors.phone = 'Phone number is required';
    } else if (phone.trim().length < 10) {
      errors.phone = 'Phone Number must be at least 10 characters';
    }
    setErrors(errors);
  };

  // Storing data from UI to db via promise
  const onSubmit = async () => {
    // Refresh the page after form submission
    setTimeout(() => window.location.reload(), 3000);
    setShowLoader(true);
    // To show the loader after submitting the form
    setTimeout(() => setShowLoader(false), 1000);
    validateForm();
    const userObj = new UpdateOrganization({
      email,
      title,
      fname,
      lname,
      jobTitle,
      phone,
      partnerCompanyName,
      companyHaedquaterAddress,
      description
    });
    const updateStatus = await userObj.updateOrgAdmin();
    switch (updateStatus.statusCode) {
      case 201:
        navigate('/btadmin');
        alert('User Updated Successfully.');
        setEmail(email);
        setTitle(title);
        setFname(fname);
        setLname(lname);
        setJobTitle(jobTitle);
        setPhone(phone);
        setPartnerCompanyName(partnerCompanyName);
        setCompanyHeadQuarterAddress(companyHaedquaterAddress);
        setDescription(description);
        break;
      case 403:
        alert('Email already Registered.');
        setEmail(email);
        setTitle(title);
        setFname(fname);
        setLname(lname);
        setJobTitle(jobTitle);
        setPhone(phone);
        setPartnerCompanyName(partnerCompanyName);
        setCompanyHeadQuarterAddress(companyHaedquaterAddress);
        setDescription(description);
        break;
      case 500:
        alert('User already exists in the Database');
        setEmail(email);
        setTitle(title);
        setFname(fname);
        setLname(lname);
        setJobTitle(jobTitle);
        setPhone(phone);
        setPartnerCompanyName(partnerCompanyName);
        setCompanyHeadQuarterAddress(companyHaedquaterAddress);
        setDescription(description);
        break;
      case 404:
        alert('Requested Organization Admin details is not found in the database.');
        setEmail(email);
        setTitle(title);
        setFname(fname);
        setLname(lname);
        setJobTitle(jobTitle);
        setPhone(phone);
        setPartnerCompanyName(partnerCompanyName);
        setCompanyHeadQuarterAddress(companyHaedquaterAddress);
        setDescription(description);
        break;
      default:
        alert('Please provide the necessary details');
    }
  };

  // Method to show the update user form on clicking the edit button
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  // Method to close the create user form on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShowForm(e);
  };

  // utility function for async react callback properties when void expected
  function wrapAsyncFunction<ARGS extends unknown[]>(
    fn: (...args: ARGS) => Promise<unknown>
  ): (...args: ARGS) => void {
    return (...args) => {
      void fn(...args);
    };
  }

  return (
    <Fragment>
      <div className="flex">
        <button onClick={handleButtonClick}>
          <ImageTag src={edit} width={'20px'} alt={''} />
        </button>
        {showForm && (
          <CustomPopup onClose={popupCloseHandler} show={showForm}>
            <div className="flex start justify-between px-4 py-4 border-b border-black">
              <h4 className="mb-0 leading-normal text-2xl text-black">BT Admin | Edit User Form</h4>
            </div>
            <div className="position-relative grow shrink basis-auto px-4 py-4 block">
              {' '}
              <form className="text-left" id="myForm" onSubmit={wrapAsyncFunction(onSubmit)}>
                <label className="inline mb-0 mt-2">
                  <span className="pr-[56rem]">Partner Admin's User Name</span>
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  style={{ border: '1px solid #444' }}
                  value={email}
                  disabled
                />
                <br />
                <label className="inline mb-0 mt-2">
                  <span className="pr-[56rem]">Creating Partner For</span>
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  style={{ border: '1px solid #444' }}
                  value={partnerCompanyName}
                  disabled
                />
                <br />
                <label className="inline mb-0 mt-2">
                  <span className="pr-[62rem]">Title</span>
                </label>
                <br />
                <Dropdown
                  options={optionTitle}
                  handleChange={(e: any) => setTitle(e.target.value)}
                  selectedValue={title}
                />
                <label className="inline mb-0 mt-2">
                  {errors.fname == null ? (
                    <span className="pr-[61rem]">First Name</span>
                  ) : (
                    <span className="pr-[55rem]">{errors.fname}</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your First Name"
                  style={
                    errors.fname == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                  value={fname}
                  onChange={(event) => setFname(event.target.value)}
                />
                <br />
                <label className="inline mb-0 mt-2">
                  {errors.lname == null ? (
                    <span className="pr-[61rem]">Last Name</span>
                  ) : (
                    <span className="pr-[55rem]">{errors.lname}</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your Last Name"
                  style={
                    errors.lname == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                  value={lname}
                  onChange={(event) => setLname(event.target.value)}
                />
                <br />
                <label className="inline mb-0 mt-2">
                  <span className="pr-[62rem]">Job Title</span>
                </label>
                <br />
                <Dropdown
                  options={optionJobTitle}
                  handleChange={(e: any) => setJobTitle(e.target.value)}
                  selectedValue={jobTitle}
                />
                <label className="inline mb-0 mt-2">
                  {errors.phone == null ? (
                    <span className="pr-[61rem]">Phone Number</span>
                  ) : (
                    <span className="pr-[55rem]">{errors.phone}</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your Phone Number"
                  style={
                    errors.phone == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />

                <br />
                <label className="inline mb-0 mt-2">
                  {errors.companyHaedquaterAddress == null ? (
                    <span className="pr-[61rem]">Company Headquarter Address</span>
                  ) : (
                    <span className="pr-[55rem]">{errors.companyHaedquaterAddress}</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your headquarter address"
                  style={
                    errors.companyHaedquaterAddress == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                  value={companyHaedquaterAddress}
                  onChange={(event) => setCompanyHeadQuarterAddress(event.target.value)}
                />
                <br />
                <label className="inline mb-0 mt-2">
                  {errors.description == null ? (
                    <span className="pr-[61rem]">Description</span>
                  ) : (
                    <span className="pr-[55rem]">{errors.description}</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your company's description"
                  style={
                    errors.description == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
                <br />
                <br />
                <Button
                  className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]"
                  buttonText="Update"
                  onclick={wrapAsyncFunction(onSubmit)}
                  loading={showLoader}
                  disabled={showLoader}
                />
              </form>
            </div>
          </CustomPopup>
        )}
      </div>
    </Fragment>
  );
};
