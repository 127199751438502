import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../library/Button/button';
import ModuleList from './ModuleList';
import UserList from './UserList';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  //  Functions to handle Tab Switching
  const handleAdminTab = () => {
    // update the state to tab1
    setActiveTab('tab1');
  };
  const handleModuleTab = () => {
    // update the state to tab2
    setActiveTab('tab2');
  };
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate('/register?btadmin=true');
  };

  return (
    <div>
      <div className="flex flex-row justify-start">
        <ul className=" flex flex-wrap ml-2.5">
          <li className="cursor-pointer" onClick={handleAdminTab}>
            <a
              className={
                activeTab === 'tab1'
                  ? 'inline-block bg-gray text-black rounded-t-lg py-4 px-4 text-[18px] text-center active bg-gray'
                  : 'inline-block text-black rounded-t-lg py-4 px-4 text-[18px] text-center'
              }
            >
              Partner Admin
            </a>
          </li>
          &nbsp;
          <li className="cursor-pointer" onClick={handleModuleTab}>
            <a
              className={
                activeTab === 'tab2'
                  ? 'inline-block bg-gray text-black rounded-t-lg py-4 px-4 text-[18px] text-center active bg-gray'
                  : 'inline-block text-black rounded-t-lg py-4 px-4 text-[18px] text-center'
              }
            >
              Modules
            </a>
          </li>
        </ul>
        <div className="mt-4 pl-[929px]">
          <Button
            className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
            buttonText="Create Organization"
            onclick={handleSubmit}
          />
        </div>
      </div>
      <div className="flex w-full">
        <div className=" md:flex lg:flex flex-row justify-start mt-8 lg:mt-18 w-[100%] pl-2.5 pr-5">
          {activeTab === 'tab1' ? <UserList /> : <ModuleList />}
        </div>
      </div>
    </div>
  );
};
export default Tabs;
