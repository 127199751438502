// These hooks are used to storing variables and will change according to user interaction
import React, { Fragment, useEffect, useState } from 'react';
import UserRow from '../UserRow';
import Search from '../AdminPanel/Search';
import Show from '../AdminPanel/Show';
import OrganizationAdmin from '../../utils/OrganizationAdmin/OrganizationAdmin';
import IUserList from '../../utils/UserList/UserList.interface';
import ReactLoading from 'react-loading';

const UserList: React.FC<any> = () => {
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently an empty array.
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetching data from db and mapping it to the array. Here the promise returned is void.
  useEffect(() => {
    const getAdminOrganization = async () => {
      const organizationAdminObj = new OrganizationAdmin();
      const result = await organizationAdminObj.getAllOrganization();
      setData(result);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    void getAdminOrganization();
  }, []);

  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white block" style={{ paddingInline: '2px 2px', width: '80%' }}>
          <br />
          <hr />
          <br />
          <Search />
          <Show />
          <br />
          <br />
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={1350}
            />
          ) : (
            <div className="w-full">
              {data.length > 0 ? (
                <table className="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-left">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Partner Name{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Admin User Name{' '}
                      </th>

                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        No. of Users{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Progress{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Assigned Modules{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Action{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {/* data is mapped into db and the values are stored of type IUserList. Here the index is of type number which generally determines the key value */}
                    {data.map((dataSet: IUserList, index: number) => (
                      <UserRow
                        key={index}
                        name={dataSet.companyName!}
                        email={dataSet.email!}
                        level={dataSet.level!}
                        progress={dataSet.progress!}
                        icon={dataSet.icon}
                        module={dataSet.module!}
                        buttonStatus={dataSet.buttonStatus!}
                        role={dataSet.role!}
                        status={dataSet.status!}
                        title={dataSet.title}
                        fname={dataSet.fname}
                        lname={dataSet.lname}
                        jobTitle={dataSet.jobTitle}
                        phone={dataSet.phone}
                        description={dataSet.description}
                        companyHaedquaterAddress={dataSet.companyHaedquaterAddress}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <h1 className="text-bold">No data found ☹️ !!</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
