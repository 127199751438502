import IUserList from '../UserList/UserList.interface';
import IOrganizationUser from './OrganizationUser.interface';

// Helper class to store all the Organization User data from db to an array
export const adminUserList = (
  arrAdmin: Array<IOrganizationUser | null>
): Array<IUserList | null> => {
  // Declaring the array as of type IUserList or null
  const arrUserList: Array<IUserList | null> = [];
  // Mapping the data from db to the array of type IOrganizationUser or null
  arrAdmin.forEach((element: IOrganizationUser | null, index) => {
    if (element) {
      arrUserList.push({
        key: index.toString(),
        name: `${element.name}`,
        email: element.email ?? '',
        level: null,
        progress: null,
        icon: undefined,
        module: null,
        status: null,
        role: element.role,
        moduleList: undefined,
        companyName: null,
        buttonStatus: null
      });
    }
  });
  return arrUserList;
};
