import React, { useState, Fragment, useEffect, FormEvent } from 'react';
import Button from '../../library/Button/button';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import create from '../../assets/img/create.png';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OrgUser from '../../utils/OrgUser/OrgUser';
import IUserList from '../../utils/UserList/UserList.interface';
import { Dropdown } from '../Dropdown';

// Re-usable component for creating Organization users for a specific Organization Admin
export const AdminUserForm = (dataSet: any) => {
  const optionJobTitle = [
    { label: '', value: '' },
    { label: 'Developer', value: 'Developer' },
    { label: 'Admin', value: 'Admin' }
  ];
  const [showForm, setShowForm] = useState<boolean>(false);
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently the db value.
  const [orgName, setOrgName] = useState(dataSet.orgName);
  const [orgEmail, setOrgEmail] = useState(dataSet.orgEmail);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);

  // Default typescript library to navigate to a certain path after user is created successfully
  const navigate = useNavigate();

  // custom hook for managing forms with ease. It takes one object as optional argument.
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Storing data from UI to db via promise
  const createUser: any = async (data: IUserList) => {
    // Refresh the page after form submission
    setTimeout(() => window.location.reload(), 3000);
    setShowLoader(true);
    // To show the loader after submitting the form
    setTimeout(() => setShowLoader(false), 1000);
    // form submission code here
    const userObj = new OrgUser({
      email: data.email ?? '',
      orgEmail,
      orgName,
      name: data.name ?? '',
      role
    });
    const createStatus = await userObj.getAllOrgUser();
    switch (createStatus.statusCode) {
      case 201:
        navigate('/btadmin');
        alert('User Created Successfully.');
        setOrgName(orgName);
        setOrgEmail(orgEmail);
        setName('');
        setEmail('');
        setRole('');
        break;
      case 403:
        alert('Email already Registered.');
        setOrgName(orgName);
        setOrgEmail(orgEmail);
        setRole('');
        setName('');
        setEmail('');
        break;
      case 500:
        alert('User already exists in the Database');
        setOrgName(orgName);
        setOrgEmail(orgEmail);
        setRole('');
        setName('');
        setEmail('');
        break;
      case 404:
        alert('Requested Organization Admin details is not found in the database.');
        setOrgName(orgName);
        setOrgEmail(orgEmail);
        setRole('');
        setName('');
        setEmail('');
        break;
      default:
        alert('Please provide the necessary details.');
    }
    console.log(email, orgEmail, orgName, name, role);
  };

  // Method to show the create user form on clicking the add button
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  // Method to close the create user form on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShowForm(e);
  };

  // utility function for async react callback properties when void expected
  function wrapAsyncFunction<ARGS extends unknown[]>(
    fn: (...args: ARGS) => Promise<unknown>
  ): (...args: ARGS) => void {
    return (...args) => {
      void fn(...args);
    };
  }

  return (
    <Fragment>
      <div className="flex">
        <button onClick={handleButtonClick}>
          <ImageTag src={create} width={'20px'} alt={''} />
        </button>
        {showForm && (
          <CustomPopup onClose={popupCloseHandler} show={showForm}>
            <div className="flex start justify-between px-4 py-4 border-b border-black">
              <h4 className="mb-0 leading-normal text-2xl text-black">
                BT Admin | Create User Form
              </h4>
            </div>
            <div className="position-relative grow shrink basis-auto px-4 py-4 block">
              {' '}
              <form
                className="text-left"
                id="myForm"
                onSubmit={wrapAsyncFunction(handleSubmit(createUser))}
              >
                <label className="inline mb-0 mt-2">
                  <span className="pr-[56rem]">Creating Partner for</span>
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your Admin User Name"
                  style={{ border: '1px solid #444' }}
                  value={orgName}
                  disabled
                />
                <br />
                <label className="inline mb-0 mt-2">
                  <span className="pr-[56rem]">Partner Admin's User Name</span>
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your Admin User Name"
                  style={{ border: '1px solid #444' }}
                  value={orgEmail}
                  disabled
                />
                <br />
                <label className="inline mb-0 mt-2">
                  {errors.name == null ? (
                    <span className="pr-[61rem]">User's Name</span>
                  ) : (
                    <span className="pr-[52rem]">Please enter your User's Name</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="text"
                  placeholder="This is usually your Admin User Name"
                  {...register('name', {
                    required: true
                  })}
                  style={
                    errors.name == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                />
                <br />
                <label className="inline mb-0 mt-2">
                  {errors.email == null ? (
                    <span className="pr-[61rem]">Email ID</span>
                  ) : (
                    <span className="pr-[52rem]">Please enter your Email ID</span>
                  )}
                </label>
                <br />
                <input
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="email"
                  placeholder="This is usually your Admin User Name"
                  {...register('email', {
                    required: true
                  })}
                  style={
                    errors.email == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                />
                <br />
                <label className="inline mb-0 mt-2">
                  <span className="pr-[56rem]">Choose your Role</span>
                </label>
                <br />
                <Dropdown
                  options={optionJobTitle}
                  handleChange={(e: any) => setRole(e.target.value)}
                  selectedValue={role}
                />
                <br />
                <Button
                  className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]"
                  buttonText="Submit"
                  onclick={wrapAsyncFunction(handleSubmit(createUser))}
                  loading={showLoader}
                  disabled={showLoader}
                />
              </form>
            </div>
          </CustomPopup>
        )}
      </div>
    </Fragment>
  );
};
