import React, { Fragment, useEffect, useState } from 'react';
import Edit from '../../assets/img/edit.png';
import Search from '../AdminPanel/Search';
import Show from '../AdminPanel/Show';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import Module from '../../utils/Module/Module';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import ReactLoading from 'react-loading';

const ModuleList: React.FC<any> = () => {
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently is false.
  const [visibility, setVisibility] = useState<boolean>(false);
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently is an empty array.
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // useEffect is declared with a dependency array. Here the promise is returned as void.
  useEffect(() => {
    const getAdminOrganizationModule = async () => {
      const organizationAdminObj = new Module();
      const result = await organizationAdminObj.getAllModule();
      setData(result);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    void getAdminOrganizationModule();
  }, []);

  // Close the pop-up form
  const popupCloseHandler = (e: boolean) => {
    setVisibility(e);
  };
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white" style={{ paddingInline: '2px 2px', width: '80%' }}>
          <br />
          <hr />
          <br />
          <Search />
          <Show />
          <br />
          <br />
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={1350}
            />
          ) : (
            <div className="w-100%">
              {data.length > 0 ? (
                <table className=" w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-left">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Module Name{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        No. of Partners{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-left">
                        {' '}
                        Action{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((element: IModuleList, index: number) => {
                      return (
                        <Fragment key={index}>
                          <tr>
                            <td className="px-6 py-4 text-center">
                              {' '}
                              <p> {element.moduleName} </p>{' '}
                            </td>
                            <td className="px-10 py-4 text-center">
                              {' '}
                              <button onClick={(e) => setVisibility(!visibility)}>
                                {element.partner}
                              </button>{' '}
                              <CustomPopup onClose={popupCloseHandler} show={visibility}>
                                <div className="flex flex start justify-between px-4 py-4 border-b border-black">
                                  <h4 className="mb-0 leading-normal text-2xl text-black">
                                    Partner Module
                                  </h4>
                                </div>
                                <div className="position-relative grow shrink basis-auto px-4 py-4 block">
                                  <table className="w-100%  border-collapse ">
                                    <thead className="table-header-group align-middle bg-[#f8f8f8] ">
                                      <tr className="text-black text-left table-row align-inherit">
                                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                          Partner Name
                                        </th>
                                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                          Access
                                        </th>
                                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {element.orgAccessList.map((info: any, index: number) => {
                                        return (
                                          <tr key={index}>
                                            <td className="px-6 py-4 text-center">
                                              {info.orgName}
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                              {info.accessType}
                                            </td>
                                            <td className="px-10 py-4 text-center">
                                              <ImageTag src={Edit} width={'20px'} alt={''} />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </CustomPopup>
                            </td>

                            <td className="pl-[2.75rem] pr-[1.75rem] py-4 object-center">
                              <div className="flex">
                                {' '}
                                <ImageTag src={Edit} width={'20px'} alt={''} />
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <h1 className="text-bold">No data found ☹️ !!</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ModuleList;
