import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../library/Button/button';
import InputField from '../../library/inputField/inputField';
import Label from '../../library/label/label';

const UserEmailInfo: React.FC<any> = (props: any) => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessahe: string | null;
  }

  // Toggle Password and Confirm Password
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [password, setPassword] = useState<boolean>(false);

  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const toggle = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPassword(!password);
  };

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';
  const buttonText1 = password ? 'Hide' : 'Show';

  // Password Validation
  const [isPasswordValid, setisPasswordValid] = useState<boolean>(false);
  const [isenableButton, setisenableButton] = useState<boolean>(false);
  const [isPwdEnable, setPwdEnable] = useState<boolean>(false);
  const [errors, setErrors] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handleChanges, isPwdEnable);
    return () => {
      document.removeEventListener('click', handleChanges, isPwdEnable);
    };
  }, [isPwdEnable]);
  const clickRefs: any = useRef(null);
  const clickRefsConfirmPassword: any = useRef(null);
  function isValidPassword(pwd: string): boolean {
    const regex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\\[\]|\\:;"'<>,.?/_₹]).{10,16}$/;
    return regex.test(pwd);
  }
  const handleChanges = (event: any): any => {
    if (clickRefs.current && !clickRefs.current.contains(event.target) && isPwdEnable) {
      if (!isValidPassword(clickRefs.current.value)) {
        setisPasswordValid(false);

        setErrors({
          errorMessage: 'Password is required. Please provide a strong password.',
          errorConfirmMessahe: ''
        });
      } else {
        setisPasswordValid(true);
        setErrors({ errorMessage: null, errorConfirmMessahe: null });
      }
      if (clickRefsConfirmPassword.current.value !== '') {
        if (clickRefsConfirmPassword.current.value !== clickRefs.current.value) {
          setisenableButton(false);

          setErrors({
            errorMessage: null,
            errorConfirmMessahe: " Oops, the password don't match. Please try again."
          });
        } else {
          setisenableButton(true);
          setErrors({ errorMessage: null, errorConfirmMessahe: null });
        }
      } else {
        if (isValidPassword(clickRefs.current.value)) {
          setErrors({
            errorMessage: null,
            errorConfirmMessahe: 'Confirm password'
          });
        }
      }
    } else {
      setErrors({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOnChanges = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'password') {
        props.setPassword({
          password: event.target.value,
          confirmPassword: props.password.confirmPassword
        });
        isValidPassword(event.target.value) ? setisPasswordValid(true) : setisPasswordValid(false);

        setErrors({
          errorMessage: null,
          errorConfirmMessahe: errors.errorConfirmMessahe
        });
      } else {
        props.setPassword({
          password: props.password.password,
          confirmPassword: event.target.value
        });
        setErrors({
          errorMessage: errors.errorMessage,
          errorConfirmMessahe: null
        });
      }
      setisenableButton(false);
    }
  };
  return (
    <Fragment>
      {props.isSaveUserPcd}
      {props.isSaveUserDetails}
      <div
        className={
          props.isSaveUserPcd && props.isSaveUserDetails
            ? 'block'
            : props.isSaveUserPcd
            ? 'block mb-[30px] mt-[72px]'
            : props.isSaveUserDetails
            ? 'block mt-[84px]'
            : 'block'
        }
      >
        <h2
          className={
            props.isSaveUserDetails && props.isSaveUserPcd
              ? 'py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
              : 'py-5 px-[30px] my-0 -mx-[30px] bg-[#ddd] text-[#333] cursor-pointer text-[1em] z-[1] min-h-[45px]'
          }
          style={{ borderTop: '1px solid #fff' }}
        >
          <span
            className={
              props.isSaveUserDetails && props.isSaveUserPcd
                ? 'text-white block'
                : 'text-[#333] block'
            }
          >
            Choose your Username
          </span>
        </h2>
      </div>
      {/* start Create BT user name */}
      {props.isSaveUserPcd && (
        <div className="block">
          <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
            Choose your username
          </h3>
          <p className="mt-0 mr-0 mb-2.5 ml-0 text-[14px]">
            To get started, choose an email address as your username. Please note that you'll need
            to access this email address to activate your ID.
          </p>
          <div className="block">
            <div className="p-0 w-full float-left relative">
              <div className="w-full my-5 mx-0 relative table border-separate">
                <Label
                  className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
                  LabelText="Username"
                ></Label>
                <div className="px-0 w-[31.25%] table">
                  <input
                    id="userEmail"
                    type="text"
                    className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                    value={props.inputEmail}
                    onChange={props.handleOnChange}
                    ref={props.clickRef}
                    required={true}
                    style={
                      props.error.errorMessage == null
                        ? { border: '1px solid #666' }
                        : { border: '1px solid #e60014' }
                    }
                    onClick={() => {
                      props.setEmailEnable(true);
                    }}
                  />
                  {props.error.errorMessage && (
                    <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                      <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                      <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                        <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                          {' '}
                          {props.error.errorMessage}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative inline-flex border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="Password"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                id="password"
                type={passwordShown ? 'text' : 'password'}
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.password.password}
                onChange={handleOnChanges}
                reference={clickRefs}
                required={true}
                style={
                  errors.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setPwdEnable(true);
                }}
              />
              {errors.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errors.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="pl-5 underline cursor-pointer text-black mt-1">
              <Link onClick={(e) => togglePassword(e)} to={''}>
                {buttonText}
              </Link>
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative inline-flex border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="Confirm password"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                id="confirmPassword"
                type={password ? 'text' : 'password'}
                className={
                  !isPasswordValid
                    ? 'h-10 block w-full py-[7px] px-[14px] rounded test-[#555] bg-[#ddd] cursor-not-allowed'
                    : 'h-10 block w-full py-[7px] px-[14px] rounded test-[#555] bg-white'
                }
                style={{ border: '1px solid #666' }}
                required={true}
                disabled={!isPasswordValid}
                reference={clickRefsConfirmPassword}
                value={props.password.confirmPassword}
                onChange={handleOnChanges}
                onClick={() => {
                  setPwdEnable(true);
                }}
              />
              {errors.errorConfirmMessahe && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errors.errorConfirmMessahe}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="pl-5 underline cursor-pointer text-black mt-1">
              <Link onClick={(e) => toggle(e)} to={''}>
                {buttonText1}
              </Link>
            </div>
          </div>
          <div className="block"></div>
          <div className="my-5 mx-0 w-full" style={{ borderBottom: '#ddd solid 1px' }}></div>
          <div className="float-left">
            <button
              className="bg-[white]  text-black border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block border leading-[1.168px]"
              onClick={() => {
                props.setSaveUserPcd(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="float-right">
            <Button
              className={
                isenableButton
                  ? 'bg-black  text-white border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
                  : 'bg-[#808080] cursor-not-allowed  text-white border-[#808080] opacity-50 py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
              }
              disabled={!isenableButton}
              onclick={props.createUser}
              buttonText="Continue"
            ></Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default UserEmailInfo;
