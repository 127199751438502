import { AxiosResponse } from 'axios';
import { put } from '../Request/Request';
import { APIResponse } from '../type';
import IUpdateOrgAdmin from './UpdateOrgAdmin.interface';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class UpdateOrganization {
  public apiResponse: APIResponse | undefined;

  // Declaring the constructor to get the request body. This constructor is of type IUpdateOrgAdmin
  constructor(public userInfo: IUpdateOrgAdmin) {}
  // Declaring the sub-path
  public subPath: string = '/organization/admin';

  // POST request for updating Organization Users and storing it to db. This methods returns a response body along with a status code.
  public async updateOrgAdmin(): Promise<APIResponse> {
    try {
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await put(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'User Updated Successfully'
          };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Email already Register'
          };
          break;
        case 500:
          this.apiResponse = {
            statusCode: 500,
            body: 'User already exists in the Database'
          };
          break;
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'Requested OrganizationAdmin details is not found in the database.'
          };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
