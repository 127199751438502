import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../library/Button/button';
import InputField from '../../library/inputField/inputField';
import Label from '../../library/label/label';
import queryString from 'query-string';

const PartnerCompanyDetails: React.FC<any> = (props: any) => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessahe: string | null;
  }
  // Partner Company Name validation
  const [isPcnValid, setisPcnValid] = useState<boolean>(false);
  const [isPcnEnable, setPcnEnable] = useState<boolean>(false);
  const [errPcn, setErrPcn] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });

  useEffect(() => {
    document.addEventListener('click', handle_chang, isPcnEnable);
    return () => {
      document.removeEventListener('click', handle_chang, isPcnEnable);
    };
  }, [isPcnEnable]);
  const click_ref1: any = useRef(null);
  function isValidPcn(pc: string): boolean {
    const regex = /^(?!\s*$).+/;
    return regex.test(pc);
  }
  const handle_chang = (event: any): any => {
    if (click_ref1.current && !click_ref1.current.contains(event.target) && isPcnEnable) {
      if (!isValidPcn(click_ref1.current.value)) {
        setisPcnValid(false);
        setErrPcn({
          errorMessage: "Company name can't be empty.",
          errorConfirmMessahe: ''
        });
      } else {
        setisPcnValid(true);
        setErrPcn({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrPcn({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_chang = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'pcn') {
        props.setpartnerCompanyName(event.target.value);
        isValidPcn(event.target.value) ? setisPcnValid(true) : setisPcnValid(false);

        setErrPcn({
          errorMessage: null,
          errorConfirmMessahe: errPcn.errorConfirmMessahe
        });
      } else {
        props.setpartnerCompanyName(props.partnerCompanyName);
        setErrPcn({
          errorMessage: errPcn.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };
  // Company Headquarters address validation
  const [isChqaValid, setisChqaValid] = useState<boolean>(false);
  const [isChqaEnable, setChqaEnable] = useState<boolean>(false);
  const [errChqa, setErrChqa] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handle_changs, isChqaEnable);
    return () => {
      document.removeEventListener('click', handle_changs, isChqaEnable);
    };
  }, [isChqaEnable]);
  const click_ref2: any = useRef(null);
  function isValidChqa(ch: string): boolean {
    const regex = /^(?!\s*$).+/;
    return regex.test(ch);
  }
  const handle_changs = (event: any): any => {
    if (click_ref2.current && !click_ref2.current.contains(event.target) && isChqaEnable) {
      if (!isValidChqa(click_ref2.current.value)) {
        setisChqaValid(false);
        setErrChqa({
          errorMessage: "Company address can't be empty.",
          errorConfirmMessahe: ''
        });
      } else {
        setisChqaValid(true);
        setErrChqa({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrChqa({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_changs = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'chqa') {
        props.setcompanyHeadQuaterAddress(event.target.value);
        isValidChqa(event.target.value) ? setisChqaValid(true) : setisChqaValid(false);

        setErrChqa({
          errorMessage: null,
          errorConfirmMessahe: errChqa.errorConfirmMessahe
        });
      } else {
        props.setcompanyHeadQuaterAddress(props.companyHeadQuaterAddress);
        setErrChqa({
          errorMessage: errChqa.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };
  // Description validation
  const [isDescValid, setisDescValid] = useState<boolean>(false);
  const [isDescEnable, setDescEnable] = useState<boolean>(false);
  const [errDesc, setErrDesc] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handle_changss, isDescEnable);
    return () => {
      document.removeEventListener('click', handle_changss, isDescEnable);
    };
  }, [isDescEnable]);
  const click_ref3: any = useRef(null);
  function isValidDesc(de: string): boolean {
    const regex = /^(?!\s*$).+/;
    return regex.test(de);
  }
  const handle_changss = (event: any): any => {
    if (click_ref3.current && !click_ref3.current.contains(event.target) && isDescEnable) {
      if (!isValidDesc(click_ref3.current.value)) {
        setisDescValid(false);
        setErrDesc({
          errorMessage: "Description can't be empty.",
          errorConfirmMessahe: ''
        });
      } else {
        setisDescValid(true);
        setErrDesc({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrDesc({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_changss = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'desc') {
        props.setdescription(event.target.value);
        isValidDesc(event.target.value) ? setisDescValid(true) : setisDescValid(false);

        setErrDesc({
          errorMessage: null,
          errorConfirmMessahe: errDesc.errorConfirmMessahe
        });
      } else {
        props.setdescription(props.description);
        setErrDesc({
          errorMessage: errDesc.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };
  return (
    <Fragment>
      <div
        className={!props.isSaveUserDetails && !props.isSaveUserPcd ? 'block  mt-[72px]' : 'block '}
      >
        <h2
          className={
            props.isSaveUserDetails && props.isSaveUserPcd
              ? 'py-5 px-[30px] my-0 -mx-[30px] bg-[#008A00] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
              : props.isSaveUserDetails && !props.isSaveUserPcd
              ? 'py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
              : 'py-5 px-[30px] my-0 -mx-[30px] bg-[#ddd] text-[#333] cursor-pointer text-[1em] z-[1] min-h-[45px]'
          }
          style={{ borderTop: '1px solid #fff' }}
        >
          <span className={props.isSaveUserDetails ? 'text-white block' : 'text-[#333] block'}>
            Partner company details
            {props.isSaveUserPcd && <span> - </span>}
            {props.isSaveUserPcd ? `${props.partnerCompanyName}` : ''}
          </span>
          {props.isSaveUserDetails && props.isSaveUserPcd && (
            <span className="block float-right h-4 text-left">
              <span
                className="flot-left py-4 px-[5px] text-[white] inline-block relative -top-[49px] underline"
                onClick={(e) => {
                  props.setSaveUserPcd(false);
                }}
              >
                Edit
              </span>
              <span
                className="inline-block relative -top-[34px]"
                style={{ transform: 'translate(0%,1%)' }}
              >
                <span
                  className="bg-[100%] bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/BT_Iconography_Confirmation_message_white.png')] h-12 w-12 overflow-hidden inline-block"
                  style={{
                    backgroundPosition: '0 0',
                    backgroundSize: '100% 100%'
                  }}
                ></span>
              </span>
            </span>
          )}
        </h2>
      </div>
      {props.isSaveUserDetails && !props.isSaveUserPcd && (
        <div className="block">
          <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
            Please enter Partner company details.
          </h3>

          <div className="block">
            <div className="p-0 w-full float-left relative">
              <div className="w-full my-5 mx-0 relative table border-separate ">
                <Label
                  className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
                  LabelText="Partner company name"
                />

                <div className="px-0 w-[31.25%] table">
                  <InputField
                    id="pcn"
                    type="text"
                    className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                    value={props.partnerCompanyName}
                    onChange={handleOn_chang}
                    reference={click_ref1}
                    required={true}
                    style={
                      errPcn.errorMessage == null
                        ? { border: '1px solid #666' }
                        : { border: '1px solid #e60014' }
                    }
                    onClick={() => {
                      setPcnEnable(true);
                    }}
                  />
                  {errPcn.errorMessage && (
                    <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                      <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                      <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                        <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                          {' '}
                          {errPcn.errorMessage}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="Company Head quarters address"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                id="chqa"
                type="text"
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.companyHeadQuaterAddress}
                onChange={handleOn_changs}
                reference={click_ref2}
                required={true}
                style={
                  errChqa.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setChqaEnable(true);
                }}
              />
              {errChqa.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errChqa.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText=" Tell us something about your organization"
            />

            <div className="px-0 w-[31.25%] table">
              <textarea
                id="desc"
                placeholder="Description of why you want to join the programme..."
                rows={4}
                defaultValue=""
                className="block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.description}
                onChange={handleOn_changss}
                ref={click_ref3}
                required={true}
                style={
                  errDesc.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setDescEnable(true);
                }}
              />
              {errDesc.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errDesc.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="block"></div>
          <div className="my-5 mx-0 w-full" style={{ borderBottom: '#ddd solid 1px' }}></div>
          <div className="float-left">
            <Button
              className="bg-[white] text-black border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block border leading-[1.168px]"
              onclick={() => {
                props.setSaveUserDetails(false);
              }}
              buttonText="Cancel"
            />
          </div>
          <div className="float-right">
            <Button
              className={
                props.partnerCompanyName && props.companyHeadQuaterAddress && props.description
                  ? 'bg-black  text-white border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
                  : 'bg-[#808080] cursor-not-allowed  text-white border-[#808080] opacity-50 py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
              }
              disabled={
                !props.partnerCompanyName && !props.companyHeadQuaterAddress && !props.description
              }
              onclick={() => {
                props.setSaveUserPcd(true);
              }}
              buttonText="Continue"
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default PartnerCompanyDetails;
