// Declaring all possible request via AXIOS and declaring the corresponding parameters required each request
import axios, { AxiosResponse } from 'axios';

export const get = async (url: string): Promise<AxiosResponse<any, any>> => {
  const response: AxiosResponse<any, any> = await axios.get(url);
  return response;
};
export const post = async (requestBody: any, url: string): Promise<AxiosResponse<any, any>> => {
  const response: AxiosResponse<any, any> = await axios.post(url, requestBody, {
    headers: { 'Content-Type': 'application/json' }
  });
  return response;
};
export const put = async (requestBody: any, url: string): Promise<AxiosResponse<any, any>> => {
  const response: AxiosResponse<any, any> = await axios.put(url, requestBody);
  return response;
};
export const deleteOne = async (url: string): Promise<AxiosResponse<any, any>> => {
  const response: AxiosResponse<any, any> = await axios.delete(url);
  return response;
};
