import React, { useState, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ImageTag from '../library/ImageTag/ImageTag';
import login from '../assets/img/login.png';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import Button from '../library/Button/button';
import User from '../utils/User/User';

const Login: React.FC<any> = (props: any) => {
  interface FormValues {
    email: string;
    password: string;
    token: string;
  }
  const [passwordShown, setPasswordShown] = useState(false);
  const [isPopupEnabled, setIsPopupEnabled] = useState(false);
  const [isResendUrl, setIsResendUrl] = useState(false);
  const [isResendSuccessful, setResendSuccessful] = useState(false);
  const [isCredentialInvalid, setCredentialInvalid] = useState(false);
  const [userName, setUserName] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [userPass, setUserPass] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const navigate: NavigateFunction = useNavigate();
  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();

  const handleChange = () => {
    setIsChecked((current) => !current);
  };

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';

  // login method for users who want to sign-in to Organization user page
  const doLogin: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault();
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 10000);
    const userObj = new User({ email: data.email || '', password: data.password || '' });
    setUserName(data.email ?? '');
    setUserPass(data.password ?? '');
    setResendSuccessful(false);
    setCredentialInvalid(false);
    setIsResendUrl(false);
    const loginStatus = await userObj.doLogin();
    switch (loginStatus.statusCode) {
      case 201:
        setIsPopupEnabled(false);
        navigate('/admin');
        break;
      case 403:
        setIsResendUrl(true);
        setCredentialInvalid(false);
        setIsPopupEnabled(true);
        break;
      default:
        setIsResendUrl(false);
        setCredentialInvalid(true);
        setIsPopupEnabled(true);
    }
    // Storing the login token and email address of the user
    sessionStorage.setItem('userEmail', data.email);
    sessionStorage.setItem('user-token', data.token);
  };

  const [visibility, setVisibility] = useState<boolean>(false);
  const popupCloseHandler = (e: boolean) => {
    window.location.reload();
    setVisibility(e);
  };

  // Resend mail for user who wants to sign-in to Organization User page
  const resendMail = async () => {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 7000);
    const adminObj = new User({ email: userName || '', password: userPass || '' });
    setCredentialInvalid(false);
    const res = await adminObj.resendUrl();
    switch (res.statusCode) {
      case 201:
        setResendSuccessful(true);
        setUserName('');
        setUserPass('');
        break;
      default:
        setResendSuccessful(false);
        setUserName('');
        setUserPass('');
    }
  };

  // Function to check if Sign-in as btadmin is checked.

  const btLogin: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault();
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 10000);
    const userObj = new User({ email: data.email || '', password: data.password || '' });
    setUserName(data.email ?? '');
    setUserPass(data.password ?? '');
    setResendSuccessful(false);
    setCredentialInvalid(false);
    setIsResendUrl(false);
    const loginStatus = await userObj.btLogin();
    switch (loginStatus.statusCode) {
      case 201:
        setIsPopupEnabled(false);
        navigate('/btadmin');
        break;
      case 403:
        setIsResendUrl(true);
        setCredentialInvalid(false);
        setIsPopupEnabled(true);
        break;
      default:
        setIsResendUrl(false);
        setCredentialInvalid(true);
        setIsPopupEnabled(true);
    }
    sessionStorage.setItem('userEmail', data.email);
    sessionStorage.setItem('user-token', data.token);
  };

  const btLoginResendMail = async () => {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 7000);
    const btAdminobj = new User({ email: userName || '', password: userPass || '' });
    setCredentialInvalid(false);
    const res = await btAdminobj.btresendUrl();
    switch (res.statusCode) {
      case 201:
        setResendSuccessful(true);
        setUserName('');
        setUserPass('');
        break;
      default:
        setResendSuccessful(false);
        setUserName('');
        setUserPass('');
    }
  };

  function wrapAsyncFunction<ARGS extends unknown[]>(
    fn: (...args: ARGS) => Promise<unknown>
  ): (...args: ARGS) => void {
    return (...args) => {
      void fn(...args);
    };
  }

  return (
    <div>
      <div className="m-0 p-0 align-baseline">
        <div className="z-10 pt-3.5 min-h-[900px] clear-both w-full relative overflow-hidden ">
          {/* Header start */}
          <Header />
          {/* Header End */}
          {/* Border Start */}
          <div
            className="w-full absolute z-50 mt-0 clear-both h-px"
            style={{
              borderTop: '1px solid #ccc'
            }}
          ></div>
          <div className="clear-both"></div>
          {/* Border End */}
          {/* Login Panel start */}
          <div
            className="w-[980px] mx-auto mt-0 mb-0 px-4 min-h-[380px] min-w-[288px] table z-[5] relative "
            style={{
              backgroundPositionX: '0px',
              backgroundPositionY: '0px'
            }}
          >
            {!isChecked ? (
              <div className="block p-[0.5rem] rounded-lg shadow-lg mt-[129px] my-8 mx-[19.625rem] pointer-events-auto float-none justify-center bg-white">
                <form
                  className="my-form"
                  method="post"
                  action=""
                  name="frm_login"
                  onSubmit={wrapAsyncFunction(handleSubmit(doLogin))}
                >
                  <div className="p-4">
                    {/* loginsection start */}
                    <div className="w-full">
                      <div className="text-[22px] text-center mb-[15px]">Log in</div>
                      <label htmlFor="validationCustomUsername" className="inline mb-0 mt-2">
                        {errors.email == null ? 'User ID' : <span>Please enter your User ID</span>}
                      </label>

                      <input
                        className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        type="email"
                        placeholder="This is usually your email address"
                        {...register('email', {
                          required: true
                        })}
                        style={
                          errors.email == null
                            ? { border: '1px solid #666' }
                            : { border: '1px solid #e60014' }
                        }
                      ></input>
                    </div>
                    {/* loginsection End */}
                    <label className="clear-left text-[#333] mt-1.5 border border-border-grey pb-2.5 hidden"></label>
                    <p className="mb-4 clear-both text-xs pb-0 mr-0 ml-0 mt-0"></p>
                    <label className="clear-left text-base inline mb-0 text-[#333] m-0 p-0 align-baseline">
                      {errors.password == null ? (
                        'Password'
                      ) : (
                        <span>Please enter your password</span>
                      )}
                    </label>
                    <Link
                      className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
                      onClick={(e) => togglePassword(e)}
                      to={''}
                    >
                      {buttonText}
                    </Link>
                    <input
                      className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')]"
                      type={passwordShown ? 'text' : 'password'}
                      {...register('password', { required: true })}
                      style={
                        errors.password == null
                          ? { border: '1px solid #666' }
                          : { border: '1px solid #e60014' }
                      }
                    ></input>
                    <p className="mb-4 clear-both text-sm pb-0 m-0 text-[#666]">
                      <Link className="text-sm text-black underline" to={''}>
                        Forgotten your log in details?
                      </Link>
                    </p>
                    <p className="mb-4 clear-both text-sm pb-0 m-0 text-[#666]">
                      <input
                        className="inline mt-1 mb-0 mr-0 ml-0 box-border"
                        type="checkbox"
                        onChange={handleChange}
                        id="check"
                        name="check"
                      ></input>
                      <strong className="text-[#333] text-xs inline top--0.5">
                        Sign-in as BT Admin
                      </strong>
                    </p>
                    <Button
                      className="p-0 tracking-normal mb-4 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]"
                      buttonText="Log in"
                      onclick={(e) => setVisibility(!visibility)}
                      loading={showLoader}
                      disabled={showLoader}
                    />
                    {isPopupEnabled && (
                      <CustomPopup onClose={popupCloseHandler} show={visibility}>
                        <div className="block">
                          <h3 className="pr-[25%] -mt-[14px] mb-2.5 text-[17px] leading-[1.5em] text-[#333]">
                            {!isResendSuccessful && !isCredentialInvalid
                              ? 'User is not confirmed'
                              : ''}
                          </h3>
                          <p className="mt-0 mr-0 mb-2.5 ml-0 text-[16px]">
                            {isResendUrl && isResendSuccessful
                              ? 'Verification mail is sent Successfully'
                              : isCredentialInvalid
                              ? 'User ID or Password is invalid'
                              : 'Click here to validate User ID'}
                          </p>
                          {isResendUrl && !isResendSuccessful && (
                            <Button
                              className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
                              buttonText="Resend"
                              onclick={wrapAsyncFunction(resendMail)}
                              loading={showLoader}
                              disabled={showLoader}
                            />
                          )}
                        </div>
                      </CustomPopup>
                    )}
                    <div className="float-none text-center mt-[15px] mb-[15px] mx-0">
                      <span className="mr-0 text-[16px] text-[#666]"> Don't have a USER ID?</span>
                      &nbsp;
                      <Link
                        className="text-[16px] text-black"
                        to={'/register'}
                        data-analytics-link="Link:"
                      >
                        Create one
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="block p-[0.5rem] rounded-lg shadow-lg mt-[129px] my-8 mx-[19.625rem] pointer-events-auto float-none justify-center bg-white">
                <form
                  className="my-form"
                  method="post"
                  action=""
                  name="frm_login"
                  onSubmit={wrapAsyncFunction(handleSubmit(btLogin))}
                >
                  <div className="p-4">
                    {/* loginsection start */}
                    <div className="w-full">
                      <div className="text-[22px] text-center mb-[15px]">Log in</div>
                      <label htmlFor="validationCustomUsername" className="inline mb-0 mt-2">
                        {errors.email == null ? 'User ID' : <span>Please enter your User ID</span>}
                      </label>

                      <input
                        className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        type="email"
                        placeholder="This is usually your email address"
                        {...register('email', {
                          required: true
                        })}
                        style={
                          errors.email == null
                            ? { border: '1px solid #666' }
                            : { border: '1px solid #e60014' }
                        }
                      ></input>
                    </div>
                    {/* loginsection End */}
                    <label className="clear-left text-[#333] mt-1.5 border border-border-grey pb-2.5 hidden"></label>
                    <p className="mb-4 clear-both text-xs pb-0 mr-0 ml-0 mt-0"></p>
                    <label className="clear-left text-base inline mb-0 text-[#333] m-0 p-0 align-baseline">
                      {errors.password == null ? (
                        'Password'
                      ) : (
                        <span>Please enter your password</span>
                      )}
                    </label>
                    <Link
                      className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
                      onClick={(e) => togglePassword(e)}
                      to={''}
                    >
                      {buttonText}
                    </Link>
                    <input
                      className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')]"
                      type={passwordShown ? 'text' : 'password'}
                      {...register('password', { required: true })}
                      style={
                        errors.password == null
                          ? { border: '1px solid #666' }
                          : { border: '1px solid #e60014' }
                      }
                    ></input>
                    <p className="mb-4 clear-both text-sm pb-0 m-0 text-[#666]">
                      <Link className="text-sm text-black underline" to={''}>
                        Forgotten your log in details?
                      </Link>
                    </p>
                    <p className="mb-4 clear-both text-sm pb-0 m-0 text-[#666]">
                      <input
                        className="inline mt-1 mb-0 mr-0 ml-0 box-border"
                        type="checkbox"
                        onChange={handleChange}
                        id="check"
                        name="check"
                      ></input>
                      <strong className="text-[#333] text-xs inline top--0.5">
                        Sign-in as BT Admin
                      </strong>
                    </p>
                    <Button
                      className="p-0 tracking-normal mb-4 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]"
                      buttonText="Log in"
                      onclick={(e) => setVisibility(!visibility)}
                      loading={showLoader}
                      disabled={showLoader}
                    />
                    {isPopupEnabled && (
                      <CustomPopup onClose={popupCloseHandler} show={visibility}>
                        <div className="block">
                          <h3 className="pr-[25%] -mt-[14px] mb-2.5 text-[17px] leading-[1.5em] text-[#333]">
                            {!isResendSuccessful && !isCredentialInvalid
                              ? 'User is not confirmed'
                              : ''}
                          </h3>
                          <p className="mt-0 mr-0 mb-2.5 ml-0 text-[16px]">
                            {isResendUrl && isResendSuccessful
                              ? 'Verification mail is sent Successfully'
                              : isCredentialInvalid
                              ? 'User ID or password is invalid'
                              : 'Click here to validate your User ID'}
                          </p>
                          {isResendUrl && !isResendSuccessful && (
                            <Button
                              className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
                              buttonText="Resend"
                              onclick={wrapAsyncFunction(btLoginResendMail)}
                              loading={showLoader}
                              disabled={showLoader}
                            />
                          )}
                        </div>
                      </CustomPopup>
                    )}
                    <div className="float-none text-center mt-[15px] mb-[15px] mx-0">
                      <span className="mr-0 text-[16px] text-[#666]"> Don't have a USER ID?</span>
                      &nbsp;
                      <Link
                        className="text-[16px] text-black"
                        to={'/register?btadmin=true'}
                        data-analytics-link="Link:"
                      >
                        Create one
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
          {/* Login Panel End */}
          {/* Background Image start */}
          <div className="w-full mt-0 mr-auto mb-0  min-h-[64.000em] absolute top-[91px] text-center z-0  block">
            <ImageTag
              src={login}
              className="relative object-cover bg-center bg-cover bg-repeat"
              alt="Home"
              height="auto"
              width="100%"
            />
          </div>
          {/* Background Image End */}
        </div>
      </div>
      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
      {/* Feed back start */}
      <div>
        <button
          id="nebula_div_btn"
          className="p-0 right-0 top-[50%] border-none z-[999999] fixed cursor-pointer leading-[1px] mt-0 w-auto normal-case overflow-visible"
        >
          <ImageTag
            alt="Feedback"
            src="https://resources.digital-cloud-uk.medallia.eu/wdcuk/244/resources/image/1534441432041_Feedback-Desktop-35X112px.png"
          />
        </button>
      </div>
      {/* Feed back End */}
    </div>
  );
};
export default Login;
