import React from 'react';
import ImageTag from '../library/ImageTag/ImageTag';
import Button from '../library/Button/button';
import edit from '../assets/img/edit.png';
import bin from '../assets/img/bin.png';
import create from '../assets/img/create.png';
import { AdminUserForm } from './BtAdminPanel/AdminUsers';
import { EditAdminUserForm } from './BtAdminPanel/EditAdminUsers';

interface UserRowProps {
  name: string | null;
  email: string | null;
  role: string | null;
  status: string | null;
  level: string | null;
  progress: string | null;
  icon: any;
  module: string | null;
  buttonStatus: string | null;
  title?: string | null;
  fname?: string | null;
  lname?: string | null;
  jobTitle?: string | null;
  phone?: string | null;
  companyHaedquaterAddress?: string | null;
  description?: string | null;
}

const UserRow: React.FC<UserRowProps> = ({
  name,
  email,
  role,
  status,
  level,
  progress,
  module,
  icon,
  buttonStatus,
  title,
  fname,
  lname,
  jobTitle,
  phone,
  companyHaedquaterAddress,
  description
}) => {
  return (
    <tr>
      <td className="px-6 py-4 text-center">
        {' '}
        <p> {name} </p>{' '}
      </td>
      {/* Conditional rendering starts */}
      {email === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {email} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className=""> {email} </p>{' '}
        </td>
      )}
      {role === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {role} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className=""> {role} </p>{' '}
        </td>
      )}
      {status === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {status} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className=""> {status} </p>{' '}
        </td>
      )}
      {level === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {level} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className=""> {level} </p>{' '}
        </td>
      )}
      {progress === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <div className="bg-[#999999] rounded-2xl   w-full">
            <p className="bg-[#2196F3] text-[12px] rounded-2xl text-white" style={{ width: '70%' }}>
              {' '}
              {progress}{' '}
            </p>{' '}
          </div>
        </td>
      ) : (
        <td className="px-6 py-4 text-center">
          {' '}
          <div className="bg-[#999999] rounded-2xl   w-full">
            <p className="bg-[#2196F3] text-[12px] rounded-2xl text-white" style={{ width: '70%' }}>
              {' '}
              {progress}{' '}
            </p>{' '}
          </div>
        </td>
      )}
      {module === null ? (
        <td className="px-6 py-4 text-center hidden">
          {' '}
          <p className=""> {module} </p>{' '}
        </td>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className=""> {module} </p>{' '}
        </td>
      )}
      {icon === undefined ? (
        <td className="px-6 py-4 text-center hidden">
          <div className="ml-[64px]">
            <ImageTag src={icon} width={'28px'} alt={''} />
          </div>
        </td>
      ) : (
        <td className="px-6 py-4 text-center">
          <div className="ml-[64px]">
            <ImageTag src={icon} width={'28px'} alt={''} />
          </div>
        </td>
      )}
      {buttonStatus === null ? (
        <td className="pl-[41px] py-4 text-center">
          {' '}
          <div className="flex">
            <button>
              <ImageTag src={edit} width={'20px'} alt={''} />
            </button>
            <button>
              <ImageTag src={bin} width={'20px'} alt={''} />
            </button>
            <button className={'hidden'}>
              <ImageTag src={create} width={'20px'} alt={''} />
            </button>
          </div>
          <Button
            className={'text-white bg-black rounded-[8px] text-[12px] px-1 -ml-[34px] hidden'}
            buttonText={buttonStatus ?? ''}
            onclick={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
          />
        </td>
      ) : (
        <td className="pl-[41px] py-4 text-center">
          <div className="flex ml-[25px]">
            <EditAdminUserForm
              title={title}
              fname={fname}
              lname={lname}
              jobTitle={jobTitle}
              phone={phone}
              email={email}
              partnerCompanyName={name}
              description={description}
              companyHaedquaterAddress={companyHaedquaterAddress}
            />
            <button>
              <ImageTag src={bin} width={'20px'} alt={''} />
            </button>
            <AdminUserForm orgName={name} orgEmail={email} />
          </div>
          <Button
            className={'text-white bg-black rounded-[8px] text-[12px] px-1 -ml-[34px]'}
            buttonText={buttonStatus ?? ''}
            onclick={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
          />
        </td>
      )}
      {/* Conditional rendering ends */}
    </tr>
  );
};

export default UserRow;
