import { AxiosResponse } from 'axios';
import { post } from '../Request/Request';
import { APIResponse } from '../type';
import IOrgUser from './OrgUser.interface';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class OrgUser {
  public apiResponse: APIResponse | undefined;

  // Declaring the constructor to get the request body. This constructor is of type IOrgUser
  constructor(public userInfo: IOrgUser) {}

  // Declaring the sub-path
  public subPath: string = '/organization/user';

  // POST request for creating Organization Users and storing it to db. This methods returns a response body along with a status code.
  public async getAllOrgUser(): Promise<APIResponse> {
    try {
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'User Created Successfully'
          };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Email already Register'
          };
          break;
        case 500:
          this.apiResponse = {
            statusCode: 500,
            body: 'User already exists in the Database'
          };
          break;
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'Requested OrganizationAdmin details is not found in the database.'
          };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
