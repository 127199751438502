import { AxiosResponse } from 'axios';
import { post } from '../Request/Request';
import { APIResponse } from '../type';
import Iuser from './User.interface';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class User {
  public apiResponse: APIResponse | undefined;

  // Declaring the constructor to get the request body. This constructor is of type IUser
  constructor(public userInfo: Iuser) {}

  // Declaring the sub-path
  public createUserPath: string = '/organization/admin';
  public loginPath: string = '/organization/admin/login';
  public validatePath: string = '/organization/admin/confirmregistration';
  public resendUrlPath: string = '/organization/admin/resendurl';
  public createBtAdminUserPath: string = '/organization/btadmin';
  public BtloginPath: string = '/organization/btadmin/login';
  public BtresendUrlPath: string = '/organization/btadmin/resendurl';

  // POST request for Registration and storing it to db. This methods returns a response body along with a status code.
  public async createUser(): Promise<APIResponse> {
    try {
      const url: string = this.createUserUrl(this.createUserPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'User Created Successfully'
          };
          break;
        default:
          this.apiResponse = {
            statusCode: response.status,
            body: 'OrganizationAdmin creation failed. Please try after sometime..'
          };
      }
    } catch (error: any) {
      switch (error?.response?.status) {
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Email already Register'
          };
          break;
        case 500:
          this.apiResponse = {
            statusCode: 500,
            body: 'User already exists in the Database'
          };
          break;
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'Requested Organization User details is not found in the database.'
          };
          break;
        case 406:
          this.apiResponse = {
            statusCode: 406,
            body: 'Organization Admin already exists in the database.'
          };
          break;
        case 400:
          this.apiResponse = {
            statusCode: 400,
            body: '400 bad request error.'
          };
          break;
        default:
          this.apiResponse = { statusCode: error?.response?.status, body: '' };
      }
    }
    return this.apiResponse;
  }

  // POST request for BT admin Registration and storing it to db. This methods returns a response body along with a status code.
  public async createBtAdminUser(): Promise<APIResponse> {
    try {
      const url: string = this.createBtAdminUserUrl(this.createBtAdminUserPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'User Created Successfully'
          };
          break;
        default:
          this.apiResponse = {
            statusCode: response.status,
            body: 'OrganizationAdmin creation failed. Please try after sometime..'
          };
      }
    } catch (error: any) {
      switch (error?.response?.status) {
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Email already Register'
          };
          break;
        case 500:
          this.apiResponse = {
            statusCode: 500,
            body: 'User already exists in the Database'
          };
          break;
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'Requested Organization User details is not found in the database.'
          };
          break;
        case 406:
          this.apiResponse = {
            statusCode: 406,
            body: 'Organization Admin already exists in the database.'
          };
          break;
        case 400:
          this.apiResponse = {
            statusCode: 400,
            body: '400 bad request error.'
          };
          break;
        default:
          this.apiResponse = { statusCode: error?.response?.status, body: '' };
      }
    }
    return this.apiResponse;
  }

  // POST request for Login and storing it to db. This methods returns a response body along with a status code.
  public async doLogin(): Promise<APIResponse> {
    try {
      const url: string = this.loginUrl(this.loginPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = { statusCode: 201, body: 'Login is successful' };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      switch (error?.response?.status) {
        case 403:
          this.apiResponse = { statusCode: 403, body: 'Username or Password not valid' };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // POST request for BT Login and storing it to db. This methods returns a response body along with a status code.
  public async btLogin(): Promise<APIResponse> {
    try {
      const url: string = this.BtloginUrl(this.BtloginPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = { statusCode: 201, body: 'Login is successful' };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      switch (error?.response?.status) {
        case 403:
          this.apiResponse = { statusCode: 403, body: 'Username or Password not valid' };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // POST request for Confirm Registration and storing it to db. This methods returns a response body along with a status code.
  public async doValidate(): Promise<APIResponse> {
    try {
      const url: string = this.validateUrl(this.validatePath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'Profile is validated'
          };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Profile is not validated'
          };
          break;
        case 500:
          this.apiResponse = {
            statusCode: 500,
            body: 'User ID or Password invalid'
          };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // POST request for Resending URL while user is not confirmed for Admin and storing it to db. This methods returns a response body along with a status code.
  public async resendUrl(): Promise<APIResponse> {
    try {
      const url: string = this.resendURL(this.resendUrlPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'Profile is validated'
          };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Profile is not validated'
          };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // POST request for Resending URL while BT Admin user is not confirmed for Admin and storing it to db. This methods returns a response body along with a status code.
  public async btresendUrl(): Promise<APIResponse> {
    try {
      const url: string = this.BtresendURL(this.BtresendUrlPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await post(requestBody, url);
      switch (response.status) {
        case 201:
          this.apiResponse = {
            statusCode: 201,
            body: 'Profile is validated'
          };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Profile is not validated'
          };
          break;
        default:
          this.apiResponse = { statusCode: response.status, body: '' };
      }
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  //   Paths for different API
  private createUserUrl(params?: string): string {
    return backEndServerUrl + params;
  }

  // Binding the Webserver path and sub-path for BT admin registration
  private createBtAdminUserUrl(params?: string): string {
    return backEndServerUrl + params;
  }

  // Binding the Webserver path and sub-path for login
  private loginUrl(paramsLogin?: string): string {
    return backEndServerUrl + paramsLogin;
  }

  // Binding the Webserver path and sub-path for login
  private BtloginUrl(paramsLogin?: string): string {
    return backEndServerUrl + paramsLogin;
  }

  // Binding the Webserver path and sub-path for Confirm registration
  private validateUrl(paramsValidate?: string): string {
    return backEndServerUrl + paramsValidate;
  }

  // Binding the Webserver path and sub-path for resendUrl
  private resendURL(paramsResendUrl?: string): string {
    return backEndServerUrl + paramsResendUrl;
  }

  // Binding the Webserver path and sub-path for resendUrl
  private BtresendURL(paramsResendUrl?: string): string {
    return backEndServerUrl + paramsResendUrl;
  }
}
